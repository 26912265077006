import * as Types from "./mutation-types";
import { RolesService, PermissionsService } from "../services";


export default {

  customFilter({commit, state}, { mutationType, type, key, value, method }){
    const filtredItems = method == 'equal' ? state[type].data.filter(role => role[key] == value)
                       : method == 'like' ? state[type].data.filter(role => role[key].toString().toLowerCase().includes(value))
                                          : state[type].data
    commit(mutationType, filtredItems);
  },
  async fetchRoles({ commit, dispatch }, { key, value, method }) {
    try {        
      let roles = await RolesService.getRoles();
      await commit(Types.GET_ROLES, roles);
      key && value && method ? await dispatch("customFilter", { mutationType: Types.GET_ROLES, type: 'roles' , key, value, method }) : null
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async addRole(_, payload) {
    try {
      await RolesService.addRole(payload);
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async editRolePermissions(_, { id , permissionIds }) {
    try {
      await RolesService.editRolePermissions(id, { permissionIds: permissionIds });
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async editRole(_, { id , payload }) {
    try {
      await RolesService.editRole(id, payload);
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async deleteRole(_, { id }) {
    try {
      await RolesService.deleteRole(id);
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },

  async fetchPermissions({ commit, dispatch }, { key, value, method }) {
    try {
      const permissions = await PermissionsService.getPermissions(); 
      await commit(Types.GET_PERMISSIONS, permissions);
      key && value && method ? await dispatch("customFilter", { mutationType: Types.GET_PERMISSIONS, type: 'permissions' , key, value, method }) : null
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async addPermission(_, payload) {
    try {
      await PermissionsService.addPermission(payload);
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async editPermission(_, { id, payload }) {
    try {
      await PermissionsService.editPermission(id, payload);
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async deletePermission(_, { id }) {
    try {
      await PermissionsService.deletePermission(id);
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
};
