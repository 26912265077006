import { parseData, stringifyData } from "../utilities/converter";
import { isNotEmpty } from "../utilities/data-check.util";

class LocalStorageService {
  set(key, data) {
    localStorage.setItem(key, stringifyData(data));
  }

  get(key) {
    return parseData(localStorage.getItem(key));
  }

  remove(key) {
    // if (!this.isExist(key)) throw new Error(`(${key}): Not exist.`);
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }

  isExist(key) {
    return isNotEmpty(this.get(key));
  }
}

export default new LocalStorageService();
