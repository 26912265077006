import { defaultState } from "@/shared/utilities/constants";
import { isEmpty } from "@/shared/utilities/data-check.util";
import * as Types from "./mutation-types";

export default {
  [Types.TEACHERS_RESULTS](state, teachersResult) {
    if (Array.isArray(teachersResult)) {
      state.teachersResult = {
        ...defaultState,
        data: teachersResult.slice(),
      };
      return;
    }

    state.teachersResult = isEmpty(teachersResult)
      ? defaultState
      : {
          pagination: teachersResult.pagination ?? { ...teachersResult, data: undefined },
          data: teachersResult.data.slice(),
        };
  },
};


