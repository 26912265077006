import * as Types from "./mutation-types";
import { StudentResultService } from "../services";

export default {
  // /**
  //  * @param {Array<{ name: string; value: string }>} queryParams
  //  * @returns {*}
  //  */
  async fetchStudentsResult({ commit }, {slectedDate , slectedOrganization , queryParams = []} ) {
    try {
      const studentsResult = await StudentResultService.result(slectedDate , slectedOrganization , queryParams);
      commit(Types.STUDENTS_RESULTS, studentsResult);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },

  async fetchExportResult(_,{slectedDate , slectedOrganization}) {
    try {
      const studentsResultFile = await StudentResultService.exportResult(slectedDate, slectedOrganization);
      const href = URL.createObjectURL(studentsResultFile);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'النتائج.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },

};
