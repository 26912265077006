export const defaultState = {
  pagination: {
    count: 0,
    total: 0,
    pageCount: 0,
    page: 1,
  },
  data: [],
};

export const USERNAME_KEY = "username";
export const PASSWORD_KEY = "password";
export const USER_KEY = "user";
export const TOKEN_KEY = "token";
export const REMEMBER_STATUS_KEY = "remember_status";
