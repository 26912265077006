import { LocalStorageService } from "@/shared/services";
import { USER_KEY } from "@/shared/utilities/constants";

const getStages = () => LocalStorageService.get(USER_KEY)?.stages;

const routes = {
  path: "/Quran",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "QuranHome",
      component: () => import("../views/QuranHomeView.vue"),
      beforeEnter(to, from, next) {
        const role = getStages().quran.read;
        if (role && to.name == "QuranHome") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
    {
      path: ":id",
      name: "Quran",
      component: () => import("../views/QuranView.vue"),
      beforeEnter(to, from, next) {
        const role = getStages().quran.read;
        if (role && to.name == "Quran") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
  ],
};

export default routes;
