import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { registerModules } from "@/shared/utilities/register-modules";

/** DASHBOARD UI MODULES */
import dashboardModule from "@/modules/ui/dashboard";

/** APP UI MODULES */
import appModule from "@/modules/ui/app";
import quranModule from "@/modules/ui/app/modules/ui/quran";
import hadithModule from "@/modules/ui/app/modules/ui/hadith";
import poetryModule from "@/modules/ui/app/modules/ui/poetry";
import quranTestModule from "@/modules/ui/app/modules/ui/quran-test";
import awkafCertificateModule from "@/modules/ui/app/modules/ui/awkaf-certificate";
import jazariaModule from "@/modules/ui/app/modules/ui/jazaria";
import checklistModule from "@/modules/ui/app/modules/ui/checklist";

/** DEFINE MODULE */
registerModules({
  dashboard: dashboardModule,
  app: appModule,
  quran: quranModule,
  hadith: hadithModule,
  quranTest: quranTestModule,
  poetry: poetryModule,
  awkafCertificate: awkafCertificateModule,
  jazaria: jazariaModule,
  checklist: checklistModule,
});

/** BOOTSTRAP VUE */
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

/** VUE TOAST */
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);

/** MULTI SELECT */
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiSelect", Multiselect);

/** V-Calendar */
// import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
// Vue.component('v-calendar', Calendar)
Vue.component('date-picker', DatePicker)

/** VuePhoneNumberInput */
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


/** Vue Advanced Cropper */
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
Vue.component(Cropper);


import checkAbility from '@/shared/directives/v-can.js'
Vue.directive('can', checkAbility)

import { can } from '@/shared/utilities/v-can.js'
Vue.prototype.$can = can

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
