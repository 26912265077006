/**
 * @param {*} data
 * @returns {string}
 */
export const stringifyData = (data) => JSON.stringify(data);

/**
 * @param {*} data
 * @returns {*}
 */
export const parseData = (data) => JSON.parse(data);
