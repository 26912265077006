import * as Types from "./mutation-types";
import { StudentService, StudentFilesService } from "../services";

export default {
  /**
   * @param {Array<{ name: string; value: string }>} queryParams
   * @returns {*}
   */
  async fetchStudent({ commit }, queryParams = []) {
    try {
      const student = await StudentService.getAll(queryParams);
      commit(Types.GET_STUDENT, student);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
  async fetchStudents({ commit }, queryParams = []) {
    try {
      const students = await StudentService.getAll(queryParams);
      commit(Types.GET_STUDENTS, students);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },

  async importStudents(_, file) {
    try {
      await StudentFilesService.import(file);
      this._vm.$toast.open({
        message: "تم الإستيراد بنجاح",
        type: "success",
        position: "top-right",
      });
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
};
