const routes = {
  path: "/",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "Organization",
      component: () => import("../views/OrganizationView.vue"),
    },
    {
      path: "Home",
      name: "Home",
      component: () => import("../views/HomeView.vue"),
    },
    {
      path: "login",
      name: "Login",
      component: () => import("@/shared/views/LoginView.vue"),
    },
    {
      path: "AccessDenied",
      name: "AccessDeniedApp",
      component: () => import("@/shared/views/AccessDeniedView.vue"),
    },
    {
      path: "Result",
      name: "Result",
      component: () => import("../views/ResultView.vue"),
    },
    {
      path: "StudentResult/:id",
      name: "StudentResultView",
      component: () => import("../views/StudentResultView.vue"),
    },
  ],
};

export default routes;
