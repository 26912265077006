import Vue from "vue";
import VueRouter from "vue-router";

import { LoggedinGuard } from "@/shared/guards/loggedin.guard";

Vue.use(VueRouter);

const routes = [
  // To Lock App
  // {
  //   path: "/AccessDeniedApp",
  //   name: "NoApp",
  //   component: () => import("@/shared/views/AccessDeniedView"),
  // }
];

const router = new VueRouter({
  // mode: "history",
  routes,
});

// Protect routes to only loggedin user.
router.beforeEach(LoggedinGuard);

export default router;
