import { HttpClient } from "@/shared/services";
// import { BaseService } from "@/shared/services";
// import { createQueryParams } from "@/shared/utilities/query-params.util";

class RolesService {
  endpoint = "roles";

  async getRoles() {
    try {
      const res = await HttpClient.get(`${this.endpoint}`);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async addRole(payload) {
    try {
      const res = await HttpClient.post(`${this.endpoint}`, payload);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async deleteRole(id) {
    try {
      const res = await HttpClient.delete(`${this.endpoint}/${id}`);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async editRolePermissions(id, permissionIds) {
    try {
      const res = await HttpClient.patch(`${this.endpoint}/${id}/permissions`, permissionIds);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async editRole(id, payload) {
    try {
      const res = await HttpClient.put(`${this.endpoint}/${id}`, payload);
      return res.data;
    } catch (err) {
      return err;
    }
  }

}

export default new RolesService();