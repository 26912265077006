import { LocalStorageService } from "@/shared/services";
import { TOKEN_KEY } from "@/shared/utilities/constants";
import { isNotEmpty } from "@/shared/utilities/data-check.util";

class TokenService {
  getToken() {
    return LocalStorageService.get(TOKEN_KEY);
  }

  getHeadersAuthorization() {
    return { Authorization: `Bearer ${this.getToken()}` };
  }

  isExist() {
    return isNotEmpty(this.getToken());
  }
}

export default new TokenService();
