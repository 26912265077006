import { defaultState } from "@/shared/utilities/constants";
import { isEmpty } from "@/shared/utilities/data-check.util";
import * as Types from "./mutation-types";

export default {
  [Types.STUDENTS_RESULTS](state, studentsResult) {
    if (Array.isArray(studentsResult)) {
      state.studentsResult = {
        ...defaultState,
        data: studentsResult.slice(),
      };
      return;
    }

    state.studentsResult = isEmpty(studentsResult)
      ? defaultState
      : {
          pagination: studentsResult.pagination ?? { ...studentsResult, data: undefined },
          data: studentsResult.data.slice(),
        };
  },
};


