import Vue from "vue";
import Vuex from "vuex";

// Modules
import StudentResultStore from "@/modules/data-access/students-result/store";
import TeachersResultStore from "@/modules/data-access/teachers-result/store";
import StudentStore from "@/modules/data-access/students/store";
import UserStore from "@/modules/data-access/users/store";
import UseruEvaluationStore from "@/modules/data-access/users-evaluation/store";
import RolesStore from "@/modules/data-access/roles/store";
import AbsencesStore from "@/modules/data-access/absences/store";
import AuthStore from "@/modules/data-access/auth/store";
import FilterStore from "@/modules/data-access/filters/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    users: [],
    students: [],
    absenceLoader: true,
    studentId: null,
    user: {},
    student: {},
    payload: {},
    currentStage: "",
    reasons: [
      { name: "فحص" },
      { name: "سفر" },
      { name: "مرض" },
      { name: "عدم تواصل" },
      { name: "إنقطاع" },
      { name: "صعوبة مواصلات" },
      { name: "دراسة" },
      { name: "زواج" },
      { name: "ولادة" },
      { name: "حامل" },
      { name: "أسباب عائلية" },
      { name: "دورة خارجية" },
      { name: "عمل" },
      { name: "أنشغال بأعمال أخرى" },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    TeachersResultStore,
    StudentResultStore,
    StudentStore,
    UserStore,
    UseruEvaluationStore,
    RolesStore,
    AbsencesStore,
    AuthStore,
    FilterStore
  },
});
