import { isEmpty } from "@/shared/utilities/data-check.util";
import { defaultState } from "@/shared/utilities/constants";
import * as Types from "./mutation-types";

export default {
  [Types.GET_STUDENTS](state, students) {
    if (Array.isArray(students)) {
      state.students = {
        ...defaultState,
        data: students.map((student) => ({
          ...student,
          reasons: state.reasons,
          selectedReason: null,
          selectedId: null,
          selectStatus: false,
          arrowStatus: false,
        })),
      };
      return;
    }

    state.students = isEmpty(students)
      ? defaultState
      : {
          pagination: students.pagination ?? { ...students, data: undefined },
          data: students.data.map((student) => ({
            ...student,
            reasons: state.reasons,
            selectedReason: null,
            selectedId: null,
            selectStatus: false,
            arrowStatus: false,
          })),
        };
  },
  [Types.GET_STUDENT](state, student) {
    if (Array.isArray(student)) {
      state.student = {
        ...defaultState,
        data: student
      };
      return;
    }

    state.student = isEmpty(student)
      ? defaultState
      : {
          pagination: student.pagination ?? { ...student, data: undefined },
          data: student.data
        };
  },

  [Types.IMPORTED_STUDENTS](state, students) {
    console.log(students);
  },
};
