import { defaultState } from "@/shared/utilities/constants";

export default () => ({
  students: { ...defaultState },
  student: { ...defaultState },
  reasons: [
    { name: "فحص" },
    { name: "سفر" },
    { name: "مرض" },
    { name: "عدم تواصل" },
    { name: "إنقطاع" },
    { name: "صعوبة مواصلات" },
    { name: "دراسة" },
    { name: "زواج" },
    { name: "ولادة" },
    { name: "حامل" },
    { name: "أسباب عائلية" },
    { name: "دورة خارجية" },
    { name: "عمل" },
    { name: "عدم الحفظ" },
    { name: "أنشغال بأعمال أخرى" },
  ],
});
