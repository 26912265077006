import store from '@/store/index';

const routes = {
  path: "/dashboard",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "DashboardHome",
      component: () => import("../views/HomeView.vue"),
    },
    {
      path: "login",
      name: "LoginDashboard",
      component: () => import("@/shared/views/LoginView.vue"),
    },
    {
      path: "AccessDenied",
      name: "AccessDeniedDashboard",
      component: () => import("@/shared/views/AccessDeniedView.vue"),
    },
    {
      path: "AbsenceManagement",
      name: "AbsenceManagement",
      component: () => import("../views/AbsenceManagementView.vue"),
      beforeEnter(to, from, next) {
        const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
        if (isAdmin || store.getters['AuthStore/getUserPermissions'].includes('absences_manage')) {
          next();
        } else {
          next({ name: "DashboardHome" });
        }
      }
    },
    {
      path: "RolesManagement",
      name: "RolesManagement",
      component: () => import("../views/RolesManagementView.vue"),
      beforeEnter(to, from, next) {
        const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
        if (isAdmin || store.getters['AuthStore/getUserPermissions'].includes('roles_manage')) {
          next();
        } else {
          next({ name: "DashboardHome" });
        }
      }
    },
    {
      path: "UsersManagement",
      name: "UsersManagement",
      component: () => import("../views/users-management/UsersManagementView.vue"),
      beforeEnter(to, from, next) {
        const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
        if (isAdmin || store.getters['AuthStore/getUserPermissions'].includes('supervisors_manage')) {
          next();
        } else {
          next({ name: "DashboardHome" });
        }
      }
    },
    {
      path: "UsersEvaluationManagement",
      name: "UsersEvaluationManagement",
      component: () => import("../views/users-management/UsersEvaluationManagementView.vue"),
      beforeEnter(to, from, next) {
        const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
        if (isAdmin || store.getters['AuthStore/getUserPermissions'].includes('supervisors_evaluation')) {
          next();
        } else {
          next({ name: "DashboardHome" });
        }
      }
    },
    {
      path: "StudentsResultManagement",
      name: "StudentsResultManagement",
      component: () => import("../views/results/StudentsResultManagementView.vue"),
      beforeEnter(to, from, next) {
        const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
        if (isAdmin || store.getters['AuthStore/getUserPermissions'].includes('results_manage')) {
          next();
        } else {
          next({ name: "DashboardHome" });
        }
      }
    },
    {
      path: "TeachersResultManagement",
      name: "TeachersResultManagement",
      component: () => import("../views/results/TeachersResultManagementView.vue"),
      beforeEnter(to, from, next) {
        const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
        if (isAdmin || store.getters['AuthStore/getUserPermissions'].includes('supervisors_results')) {
          next();
        } else {
          next({ name: "DashboardHome" });
        }
      }
    },
    {
      path: "StudentsManagement",
      component: () => import("../views/students-management/Index.vue"),
      children: [
        {
          path: "",
          name: "StudentsManagement",
          component: () => import("../views/students-management/StudentsManagementView.vue"),
        },
        {
          path: ":id",
          name: "StudentDetails",
          component: () => import("../views/students-management/StudentDetailsView.vue"),
          // beforeEnter(to, from, next) {
          //   const isAdmin = getUser().type == 'admin' ? true : false;
          //   if (isAdmin) {
          //     next();
          //   } else {
          //     next({ name: "DashboardHome" });
          //   }
          // },
        },
        {
          path: "Absences/:id",
          name: "StudentAbsencesDetails",
          component: () => import("../views/students-management/StudentAbsencesDetailsView.vue"),
          // beforeEnter(to, from, next) {
          //   const isAdmin = getUser().type == 'admin' ? true : false;
          //   if (isAdmin) {
          //     next();
          //   } else {
          //     next({ name: "DashboardHome" });
          //   }
          // },
        },
      ],
      beforeEnter(to, from, next) {
        const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
        if (isAdmin || store.getters['AuthStore/getUserPermissions'].includes('students_manage')) {
          next();
        } else {
          next({ name: "DashboardHome" });
        }
      }
    },
  ],
};

export default routes;



