import { defaultState } from "@/shared/utilities/constants";
import { isEmpty } from "@/shared/utilities/data-check.util";
import * as Types from "./mutation-types";

export default {
  [Types.GET_ABSENCES](state, absences) {
    if (Array.isArray(absences)) {
      state.absences = {
        ...defaultState,
        data: absences.slice(),
      };
      return;
    }

    state.absences = isEmpty(absences)
      ? defaultState
      : {
          pagination: absences.pagination ?? { ...absences, data: undefined },
          data: absences.data.slice(),
        };
  },
  [Types.GET_ABSENCES_DATES](state, dates) {
    state.dates = dates ?? [];
  },
  [Types.GET_ABSENCES_REASONS](state, reasons) {
    state.reasons = reasons ?? {
      count: 0,
      data: [],
    };
  },
};
