import { HttpClient } from "@/shared/services";

class UserService {
  endpoint = "session/supervisors-evaluation";

  async result(slectedDate, slectedOrganization) {
    try {
      const res = await HttpClient.post(`${this.endpoint}/show?organization=${slectedOrganization}` , slectedDate);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async exportResult(slectedDate, slectedOrganization) {
    try {
      const res = await HttpClient.post(`${this.endpoint}/export?organization=${slectedOrganization}` , slectedDate, { responseType: 'blob' });
      return res.data;
    } catch (err) {
      return err;
    }
  }
}

export default new UserService();