import * as Types from "./mutation-types";
import { UserEvaluationService } from "../services";

export default {
  /**
   * @param {Array<{ name: string; value: string }>} queryParams
   * @returns
   */

  customFilter({commit, state}, { mutationType, type, key, value, method }){
    const filtredItems = method == 'equal' ? state[type].data.filter(user => user[key] == value)
                        : method == 'like' ? state[type].data.filter(user => user[key].toString().toLowerCase().includes(value))
                                           : state[type].data
    commit(mutationType, filtredItems);
  },

  async fetchUsersEvaluation({ commit, dispatch }, { key, value, method, slectedDate, slectedOrganization }) {
    try {
      const usersEvaluation = await UserEvaluationService.result(slectedDate, slectedOrganization);
      await commit(Types.GET_EVALUATION, usersEvaluation);
      key && value && method ? await dispatch("customFilter", { mutationType: Types.GET_EVALUATION, type: 'usersEvaluation' , key, value, method }) : null
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },

  async fetchExportUsersEvaluation(_ ,{ slectedDate, slectedOrganization }) {
    try {
      const studentsResultFile = await UserEvaluationService.exportResult(slectedDate, slectedOrganization);
      const href = URL.createObjectURL(studentsResultFile);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'تقييم المشرفات.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
};