import { defaultState } from "@/shared/utilities/constants";
import { isEmpty } from "@/shared/utilities/data-check.util";
import * as Types from "./mutation-types";

export default {

  [Types.GET_EVALUATION](state, usersEvaluation) {
    if (Array.isArray(usersEvaluation)) {
      state.usersEvaluation = {
        ...defaultState,
        data: usersEvaluation.slice(),
      };
      return;
    }

    state.usersEvaluation = isEmpty(usersEvaluation)
      ? defaultState
      : {
          pagination: usersEvaluation.pagination ?? { ...usersEvaluation, data: undefined },
          data: usersEvaluation.data.slice(),
        };
  },
};