export const isNotEmpty = (value) => {
    if (isObject(value)) return Object.keys(value).length > 0;
    if (Array.isArray(value)) return value.length > 0;
    return value !== null || value !== undefined || value !== '';
}

export const isEmpty = (value) => !isNotEmpty(value)

export const isObject = (value) => {
    if (value === null) return false;
    return typeof value === 'function' || typeof value === 'object';
}

export const isExpired = (tokenDate) => tokenDate < Date.now()