import store from "@/store";
import router from "@/router";

const registerModule = (name, module) => {
  if (module.store) {
    store.registerModule(name, module.store);
  }

  if (module.router) {
    router.addRoute(module.router);
  }
};

export const registerModules = (modules) => {
  for (const moduleKey in modules) {
    registerModule(moduleKey, modules[moduleKey]);
  }
};
