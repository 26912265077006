import axios from "axios";
import { TokenService } from "@/modules/data-access/auth/services";
import { API_URL } from "@/environment";
import router from "@/router";
import { LocalStorageService } from "../services";
import { TOKEN_KEY, USER_KEY, USERNAME_KEY ,PASSWORD_KEY ,REMEMBER_STATUS_KEY } from "@/shared/utilities/constants";

const isComingFromDashboard = (url) => url.fullPath.toLowerCase().includes("dashboard");
const isLoginPage = (url) => url.fullPath.toLowerCase().includes("login");
const navigateToAccessDenied = () => {
  router.push({name: isComingFromDashboard(router.currentRoute) ? "AccessDeniedDashboard" : "AccessDeniedApp"})
}
const navigateToLogin = () => {
  LocalStorageService.remove(USER_KEY);
  LocalStorageService.remove(TOKEN_KEY);
  LocalStorageService.remove(USERNAME_KEY);
  LocalStorageService.remove(PASSWORD_KEY);
  LocalStorageService.remove(REMEMBER_STATUS_KEY);
  if (!isLoginPage(router.currentRoute))
    router.push({name: isComingFromDashboard(router.currentRoute) ? "LoginDashboard" : "Login"})
}

const httpClient = axios.create({
  baseURL: API_URL,
  headers: { "Accept": "application/json" }
});

httpClient.interceptors.request.use(
  (config) => {
    if (TokenService.isExist()) {
      config.headers.common = { ...TokenService.getHeadersAuthorization() , "x-app-type": isComingFromDashboard(router.currentRoute) ? "dashboard" : 'app' };
    }
    return config;
  },
  (error) => Promise.reject(error)
);
httpClient.interceptors.response.use(
  null,
  (error) => {
    if(error.response.data.statusCode == 403) {
      navigateToAccessDenied()
    } else if (error.response.data.statusCode == 401) {
      navigateToLogin()
    }
    return Promise.reject(error)
  }
);

export default httpClient;
