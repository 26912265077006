const routes = {
  path: "/Jazaria",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "JazariaHomeView",
      component: () => import("../views/JazariaHomeView.vue"),
    },
    {
      path: ":id",
      name: "JazariaView",
      component: () => import("../views/JazariaView.vue"),
    },
  ],
};

export default routes;
