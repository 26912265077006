/**
 * @param {Array<{ name: string; value: any }>} params
 * @returns
 */
export function createQueryParams(params) {
  let query = "";

  if (params.length == 0) return "";

  params.forEach((param, index) => {
    let paramValue = "";

    if (!param?.name) return;

    if (typeof param.value === "object") {
      paramValue = getObjectValues(param.value);
    } else {
      paramValue = param.value;
    }

    query += `${param.name}=${paramValue}${
      params.length > index + 1 ? "&" : ""
    }`;
  });
  return `?${query}`;
}

function getObjectValues(object) {
  for (const key in object) {
    if (!object[key]) {
      delete object[key];
    }
  }
  return JSON.stringify(object);
}
