import { LocalStorageService } from "@/shared/services";
import {
  PASSWORD_KEY,
  REMEMBER_STATUS_KEY,
  TOKEN_KEY,
  USERNAME_KEY,
  USER_KEY,
} from "@/shared/utilities/constants";
import * as Types from "./mutation-types";

export default {
  [Types.LOGIN](state, { loggedInUser, username, password, rememberStatus }) {
    state.user = loggedInUser.user;
    state.token = loggedInUser.tokens.access_token;

    LocalStorageService.set(USERNAME_KEY, username);
    LocalStorageService.set(PASSWORD_KEY, password);
    LocalStorageService.set(REMEMBER_STATUS_KEY, rememberStatus);
    LocalStorageService.set(USER_KEY, loggedInUser.user);
    LocalStorageService.set(TOKEN_KEY, loggedInUser.tokens.access_token);
  },
  [Types.REFRESH_USER](state, user) {
    state.user = user;
    LocalStorageService.set(USER_KEY, user);
  },
  [Types.LOGOUT](state) {
    state.user = {};
    state.token = {};
  },
};
