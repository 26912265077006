import * as Types from "./mutation-types";
import { UserService, UserFilesService } from "../services";

export default {
  /**
   * @param {Array<{ name: string; value: string }>} queryParams
   * @returns
   */
  async fetchUsers({ commit }, queryParams = []) {
    try {
      const users = await UserService.getAll(queryParams);
      commit(Types.GET_USERS, users);
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
    }
  },
  async importUsers(_, file) {
    try {
      await UserFilesService.import(file);
      this._vm.$toast.open({
        message: "تم الإستيراد بنجاح",
        type: "success",
        position: "top-right",
      });
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
};
