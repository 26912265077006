import { defaultState } from "@/shared/utilities/constants";
import { isEmpty } from "@/shared/utilities/data-check.util";
import * as Types from "./mutation-types";

export default {
  /**
   * @param {*} state
   * @param {*} users
   */

  [Types.GET_ROLES](state, roles) {
    if (Array.isArray(roles)) {
      state.roles = {
        ...defaultState,
        data: roles.slice(),
      };
      return;
    }

    state.users = isEmpty(roles)
      ? defaultState
      : {
          pagination: roles.pagination ?? { ...roles, data: undefined },
          data: roles.data.slice(),
        };
  },
  [Types.GET_PERMISSIONS](state, permissions) {
    if (Array.isArray(permissions)) {
      state.permissions = {
        ...defaultState,
        data: permissions.slice(),
      };
      return;
    }

    state.permissions = isEmpty(permissions)
      ? defaultState
      : {
          pagination: permissions.pagination ?? { ...permissions, data: undefined },
          data: permissions.data.slice(),
        };
  }
};
