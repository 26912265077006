import { LocalStorageService } from "../services";
import { TOKEN_KEY, USER_KEY, USERNAME_KEY ,PASSWORD_KEY ,REMEMBER_STATUS_KEY } from "@/shared/utilities/constants";
import store from '@/store/index';
const PUBLIC_PAGES = ["Login", "LoginDashboard"];
const isComingFromDashboard = (url) => url.fullPath.toLowerCase().includes("dashboard");

export function LoggedinGuard(to, from, next) {
  const loginPagePath = isComingFromDashboard(to) ? "LoginDashboard" : "Login";
  const homePagePath = isComingFromDashboard(to) ? "DashboardHome" : "Organization";
  const authRequired = !PUBLIC_PAGES.includes(to.name);
  const loggedIn = LocalStorageService.get(USER_KEY)?.type ? true : false;
  const canAccessDashboard = store.getters['AuthStore/getLoggedInUser']?.canAccessDashboard ?? false
  const canAccessApp = store.getters['AuthStore/getLoggedInUser']?.canAccessApp ?? false

  if(authRequired && !loggedIn){
    LocalStorageService.remove(USER_KEY);
    LocalStorageService.remove(TOKEN_KEY);
    LocalStorageService.remove(USERNAME_KEY);
    LocalStorageService.remove(PASSWORD_KEY);
    LocalStorageService.remove(REMEMBER_STATUS_KEY);
    next({ name: loginPagePath })
  } else if (!authRequired && loggedIn){
    next({ name: homePagePath })
  } else if(to.name != 'AccessDeniedDashboard' && isComingFromDashboard(to) && !canAccessDashboard && loggedIn){
    next({ name: 'AccessDeniedDashboard' })
  } else if (to.name != 'AccessDeniedApp' && !isComingFromDashboard(to) && !canAccessApp && loggedIn) {
    next({ name: 'AccessDeniedApp' })
  } else {
    next()
  }
    
}
// To Lock App
// const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
// } else if (to.name != 'NoApp' && !isComingFromDashboard(to) && authRequired && !isAdmin) {
//   next({ name: 'NoApp' })
// }