import { LocalStorageService } from "@/shared/services";
import { USER_KEY } from "@/shared/utilities/constants";

const getStages = () => LocalStorageService.get(USER_KEY)?.stages;

const routes = {
  path: "/Quran-Test",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "QuranTestHome",
      component: () => import("../views/QuranTestHomeView.vue"),
      beforeEnter(to, from, next) {
        const role = getStages().saberQuran.read || getStages().saberQuranFardy.read;
        if (role && to.name == "QuranTestHome") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
    {
      path: ":id",
      name: "QuranTest",
      component: () => import("../views/QuranTestView.vue"),
      beforeEnter(to, from, next) {
        const role = getStages().saberQuran.read || getStages().saberQuranFardy.read;
        if (role && to.name == "QuranTest") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
  ],
};

export default routes;
