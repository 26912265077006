import { LocalStorageService } from "@/shared/services";
import { AuthService } from "../services";
import { TOKEN_KEY, USER_KEY, USERNAME_KEY ,PASSWORD_KEY ,REMEMBER_STATUS_KEY } from "@/shared/utilities/constants";
import router from "@/router";
import * as Types from "./mutation-types";
import store from '@/store/index';

export default {
  /**
   * @param {{user: {username: string; password: string}, from: string, rememberStatus: boolean}} payload
   */
  async login({ commit }, payload) {
    try {
      if (payload.user?.username == null || payload.user?.password == null) {
        this._vm.$toast.open({
          message: "الرجاء إدخال البيانات بالطريقة الصحيحة",
          type: "error",
          position: "top-right",
        });
        return;
      }

      const user = await AuthService.login(payload.user);
      commit(Types.LOGIN, {
        loggedInUser: user,
        username: payload.user.username,
        password: payload.user.password,
        rememberStatus: payload.rememberStatus,
      });
      // const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false;
      const canAccessDashboard = store.getters['AuthStore/getLoggedInUser']?.canAccessDashboard ?? false
      const canAccessApp = store.getters['AuthStore/getLoggedInUser']?.canAccessApp ?? false
      router.push({
        name: payload.from == "client" && canAccessApp ? "Organization" :
             payload.from == "client" && !canAccessApp ? "AccessDeniedApp" :
         payload.from == "admin" && canAccessDashboard ? "DashboardHome" :
        payload.from == "admin" && !canAccessDashboard ? "AccessDeniedDashboard" 
                                                       : null,
      });
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },

  async refreshUser({ commit }, { from } = { from: "client" }) {
    try {
      const user = await AuthService.refreshUser();
      commit(Types.REFRESH_USER, user);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message:
          err?.code == "ERR_NETWORK"
            ? "لا يوجد اتصال بالانترنت"
            : "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      router.push({
        name: from == "client" ? "Login" : "LoginDashboard",
      });
      return err;
    }
  },

  logout({ commit }, { from }) {
    LocalStorageService.remove(USER_KEY);
    LocalStorageService.remove(TOKEN_KEY);
    LocalStorageService.remove(USERNAME_KEY);
    LocalStorageService.remove(PASSWORD_KEY);
    LocalStorageService.remove(REMEMBER_STATUS_KEY);
    commit(Types.LOGOUT);
    router.push({
      name: from == "client" ? "Login" : "LoginDashboard",
    });
  },
};
