import * as Types from "./mutation-types";
import { AbsencesService } from "../services";

export default {
  /**
   * @param {Array<{ name: string; value: string }>} queryParams
   * @returns {*}
   */
  async fetchAbsences({ commit }, queryParams = []) {
    try {
      const absences = await AbsencesService.getAll(queryParams);
      commit(Types.GET_ABSENCES, absences);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
  /**
   * @param {number} id
   * @returns {Promise}
   */
  async fetchAbsenceById({ commit }, id) {
    try {
      const absence = await AbsencesService.getById(id);
      commit(Types.GET_ABSENCE_BY_ID, absence);
      return "success";
    } catch (err) {
      return err;
    }
  },
  /**
   * @param {Array<{ name: string; value: string }>} queryParams
   * @returns {*}
   */
  async fetchAbsencesDates({ commit }, queryParams = []) {
    try {
      const dates = await AbsencesService.getDates(queryParams);
      commit(Types.GET_ABSENCES_DATES, dates);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
  /**
   * @param {Array<string>} dates
   * @returns {*}
   */
  async fetchAbsencesReasons({ commit }, dates = []) {
    try {
      const reasons = await AbsencesService.getReasons(dates);
      commit(Types.GET_ABSENCES_REASONS, reasons);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
  async createAbsence(_, { reasons }) {
    try {
      const res = await AbsencesService.create(reasons);
      this._vm.$toast.open({
        message: "تم الإرسال بنجاح",
        type: "success",
        position: "top-right",
      });
      return res;
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
};
