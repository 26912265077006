import { LocalStorageService } from "@/shared/services";
import { USER_KEY } from "@/shared/utilities/constants";

const getStages = () => LocalStorageService.get(USER_KEY)?.stages;

const routes = {
  path: "/Hadith",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "HadithHome",
      component: () => import("../views/HadithHomeView.vue"),
      beforeEnter(to, from, next) {
        const role1 = getStages()?.riyadAlSaliheen?.read;
        const role2 = getStages()?.ahadethQosaya?.read;
        const role3 = getStages()?.arbaonNawawea?.read;
        const role4 = getStages()?.maalemAlsounah?.read;
        if (role1 || role2 || role3 || role4) {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
    {
      path: "Options",
      name: "HadithOptionsView",
      component: () => import("../views/HadithOptionsView.vue"),
      beforeEnter(to, from, next) {
        const role1 = getStages()?.riyadAlSaliheen?.read;
        const role2 = getStages()?.ahadethQosaya?.read;
        const role3 = getStages()?.arbaonNawawea?.read;
        const role4 = getStages()?.maalemAlsounah?.read;
        if (role1 || role2 || role3 || role4) {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
    {
      path: ":name/:id",
      name: "Hadith",
      component: () => import("../views/HadithView.vue"),
      props: {
        default: true,
      },
      beforeEnter(to, from, next) {
        const role1 = getStages()?.riyadAlSaliheen?.read;
        const role2 = getStages()?.ahadethQosaya?.read;
        const role3 = getStages()?.arbaonNawawea?.read;
        const role4 = getStages()?.maalemAlsounah?.read;
        if (role1 && to.params.name == "riyadAlSaliheen") {
          next();
        } else if (role2 && to.params.name == "ahadethQosaya") {
          next();
        } else if (role3 && to.params.name == "arbaonNawawea") {
          next();
        } else if (role4 && to.params.name == "maalemAlsounah") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
  ],
};

export default routes;
