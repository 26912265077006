import * as Types from "./mutation-types";

export default {
  // dispatch
  filterData({ commit }, { filterSchema }) {
    console.log(filterSchema);
    commit(Types.SEND_FILTERS, {});
  },
  resetFilterPayload({ commit }, { filterMethod }) {
    console.log(filterMethod);
    commit(Types.RESET_FILTER_PAYLOAD);
  },
};
