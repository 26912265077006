import { HttpClient } from "@/shared/services";

class StudentFilesService {
  endpoint = "users";

  async import(file) {
    try {
      const res = await HttpClient.post(`${this.endpoint}/import` , file);
      return res.data;
    } catch (err) {
      throw err(err);
    }
  }
}

export default new StudentFilesService();
