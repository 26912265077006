import { HttpClient } from "@/shared/services";
// import { BaseService } from "@/shared/services";
// import { createQueryParams } from "@/shared/utilities/query-params.util";
    
class RolesService {
  endpoint = "permissions";

  // async getPermissions(queryParams) {
  async getPermissions() {
    try {
      // const res = await HttpClient.get(`${this.endpoint}${createQueryParams(queryParams)}`);
      const res = await HttpClient.get(`${this.endpoint}`);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async addPermission(payload) {
    try {
      const res = await HttpClient.post(`${this.endpoint}`, payload);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async deletePermission(id) {
    try {
      const res = await HttpClient.delete(`${this.endpoint}/${id}`);
      return res.data;
    } catch (err) {
      return err;
    }
  }
  async editPermission(id, payload) {
    try {
      const res = await HttpClient.put(`${this.endpoint}/${id}`, payload);
      return res.data;
    } catch (err) {
      return err;
    }
  }
}

export default new RolesService();