import { BaseService, HttpClient } from "@/shared/services";
import { createQueryParams } from "@/shared/utilities/query-params.util";

class AbsencesService extends BaseService {
  endpoint = "absences";

  async getDates(queryParams) {
    try {
      const res = await HttpClient.get(
        `${this.endpoint}/dates${createQueryParams(queryParams)}`
      );
      return res?.data;
    } catch (err) {
      return err;
    }
  }

  async getReasons(dates) {
    try {
      let res
      if (dates.length >= 1) {
        res = await HttpClient.post(`${this.endpoint}/reasons`, { dates });
      } else {
        res = await HttpClient.post(`${this.endpoint}/reasons`);
      }
      return res?.data;
    } catch (err) {
      return err;
    }
  }

  async create(reasons) {
    try {
      const res = await HttpClient.post(`${this.endpoint}`, {...reasons});
      return res.data;
    } catch (err) {
      throw err(err);
    }
  }
}

export default new AbsencesService();
