import { LocalStorageService } from "@/shared/services";
import { USER_KEY } from "@/shared/utilities/constants";

const getStages = () => LocalStorageService.get(USER_KEY)?.stages;

const routes = {
  path: "/Checklist",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "Checklist",
      component: () => import("../views/ChecklistHomeView"),
      beforeEnter(to, from, next) {
        const role = getStages().absenceLog.read;
        if (role && to.name == "Checklist") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
    {
      path: "AddStudent",
      name: "NewStudentForm",
      component: () => import("../views/NewStudentFormView"),
      beforeEnter(to, from, next) {
        const role = getStages().absenceLog.read;
        if (role && to.name == "NewStudentForm") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
  ],
};

export default routes;
