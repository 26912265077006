import store from '@/store/index';

export default {
    // bind: function (el, binding, vnode) {
    inserted: function (el, binding) {
      const permissions = Array.from(new Set(store.getters['AuthStore/getUserPermissions'])) ?? []
      const isAdmin = store.getters['AuthStore/getUserType'] == 'admin' ? true : false
      const behaviour = binding.modifiers.disable ? 'disable' : 'hide'
      const isPermissionExist = permissions.includes(binding.value)
      if (!isAdmin && behaviour == 'disable' && !isPermissionExist) {
        el.disabled = true
      } else if (!isAdmin && behaviour == 'hide' && !isPermissionExist) {
          el.parentNode.removeChild(el)
          // const comment = document.createComment(" ");
          // Object.defineProperty(comment, "setAttribute", {
          //   value: () => undefined
          // });
          // vnode.elm = comment;
          // vnode.text = " ";
          // vnode.isComment = true;
          // vnode.context = undefined;
          // vnode.tag = undefined;
          // vnode.data.directives = undefined;
          // if (vnode.componentInstance) {
          //   vnode.componentInstance.$el = comment;
          // }
          // if (el.parentNode) {
          //   el.parentNode.replaceChild(comment, el);
          // }
          
          // vnode.elm.hidden  = "none"
          // el.style.display = "none";
          // }
      }

  }
}

