import { HttpClient } from "@/shared/services";
import { createQueryParams } from "../utilities/query-params.util";

export class BaseService {
  endpoint = "";

  /**
   * @param {Array<{ name: string; value: string }>} queryParams
   * @returns {*}
   */
  async getAll(queryParams) {
    try {
      const res = await HttpClient.get(
        `${this.endpoint}${createQueryParams(queryParams)}`
      );
      return res?.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * @param {number} id
   * @returns {*}
   */
  async getById(id) {
    try {
      const res = await HttpClient.get(`${this.endpoint}/${id}`);
      return res?.data;
    } catch (err) {
      return err;
    }
  }
}
