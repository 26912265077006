import { LocalStorageService } from "@/shared/services";
import { USER_KEY } from "@/shared/utilities/constants";

const getStages = () => LocalStorageService.get(USER_KEY)?.stages;

const routes = {
  path: "/Awkaf-Certificate",
  component: () => import("@/shared/views/ModuleOutlet.vue"),
  children: [
    {
      path: "",
      name: "AwkafCertificateHome",
      component: () => import("../views/AwkafCertificateHomeView.vue"),
      beforeEnter(to, from, next) {
        const role = getStages().ShadadAwkaf.read;
        if (role && to.name == "AwkafCertificateHome") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
    {
      path: ":id",
      name: "AwkafCertificate",
      component: () => import("../views/AwkafCertificateView.vue"),
      beforeEnter(to, from, next) {
        const role = getStages().ShadadAwkaf.read;
        if (role && to.name == "AwkafCertificate") {
          next();
        } else {
          next({ name: "Home" });
        }
      },
    },
  ],
};

export default routes;
