import { defaultState } from "@/shared/utilities/constants";
import { isEmpty } from "@/shared/utilities/data-check.util";
import * as Types from "./mutation-types";

export default {
  /**
   * @param {*} state
   * @param {*} users
   */
  [Types.GET_USERS](state, users) {
    if (Array.isArray(users)) {
      state.users = {
        ...defaultState,
        data: users.slice(),
      };
      return;
    }

    state.users = isEmpty(users)
      ? defaultState
      : {
          pagination: users.pagination ?? { ...users, data: undefined },
          data: users.data.slice(),
        };
  },
};
