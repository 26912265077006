export default {
  getStudents: (state) =>
    state.students?.data?.map((student) => ({
      ...student,
      supervisorName: student.supervisor?.name,
    })),
  getStudentsWithReasons: (state) => state?.students?.data,
  getStudentsPagination: (state) => state?.students?.pagination,
  getStudent: (state) =>
  state.student?.data?.map((student) => ({
    ...student,
    supervisorName: student.supervisor?.name,
  })),
  getStudentPagination: (state) => state?.student?.pagination,
};
