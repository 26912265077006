import { LocalStorageService } from "@/shared/services";
import { isNotEmpty } from "@/shared/utilities/data-check.util";
import { TOKEN_KEY, USER_KEY } from "@/shared/utilities/constants";

export default {
  getLoggedInUser: (state) => {
    return isNotEmpty(state.user)
      ? state.user
      : LocalStorageService.get(USER_KEY);
  },
  getUserToken: (state) => {
    return isNotEmpty(state.token)
      ? state.token
      : LocalStorageService.get(TOKEN_KEY);
  },
  getUserStages: (state) => {
    return isNotEmpty(state.user)
      ? state?.user?.stages
      : LocalStorageService.get(USER_KEY)?.stages;
  },
  getUserType: (state) => {
    return isNotEmpty(state.user)
      ? state?.user?.type
      : LocalStorageService.get(USER_KEY)?.type;
  },
  getUserPermissions: (state) => {
    return isNotEmpty(state.user)
      ? state?.user?.allPermissions
      : LocalStorageService.get(USER_KEY)?.allPermissions;
  },
  getUserOrganization: (state) => {
    return isNotEmpty(state.user)
      ? state?.user?.organization
      : LocalStorageService.get(USER_KEY)?.organization;
  },
};
