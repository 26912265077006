import * as Types from "./mutation-types";
import { TeachersResultService } from "../services";

export default {
  // /**
  //  * @param {Array<{ name: string; value: string }>} queryParams
  //  * @returns {*}
  //  */
  async fetchTeachersResult({ commit }, {slectedDate, slectedOrganization, queryParams = []} ) {
    try {
      const teachersResults = await TeachersResultService.result(slectedDate, slectedOrganization, queryParams);
      commit(Types.TEACHERS_RESULTS, teachersResults);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
  async fetchExportTeachersResult(_, {slectedDate, slectedOrganization}) {
    try {
      const teachersResultFile = await TeachersResultService.exportResult(slectedDate, slectedOrganization);
      const href = URL.createObjectURL(teachersResultFile);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'النتائج.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return "success";
    } catch (err) {
      this._vm.$toast.open({
        message: "خطأ غير متوقع !!",
        type: "error",
        position: "top-right",
      });
      return err;
    }
  },
};
