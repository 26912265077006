<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  methods: {
    updateOnlineStatus(e) {
      if (e.type == 'online') {
        this.$toast.clear();
      } else {
        this.$toast.open({
          message: 'لا يوجد اتصال بالانترنت',
          type: 'error',
          position: 'top-right',
          duration: 999999,
        });
      }
    },
  },
  created() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  destroyed() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
}

</script>
  
<style>
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

#app {
  font-family: 'Amiri', serif;
  direction: rtl !important;
}

.modal {
  direction: rtl !important;
  font-family: 'Tajawal', sans-serif !important;
}

.v-toast__item .v-toast__item--error .v-toast__item--top-right {
  flex-direction: row-reverse !important;
}

.v-toast__item {
  max-width: 70% !important;
  flex-direction: row-reverse !important;
}

.v-toast__text {
  direction: rtl !important;
  padding: 0.2em 0.5em !important;
  font-family: 'Tajawal', sans-serif !important;
}

.v-toast__item .v-toast__text {
  padding: 0.5em 1em !important;
  font-size: 15px !important;
}

.v-toast__icon {
  margin-left: 0.5em !important;
  margin-right: 1em !important;
}
</style>
