import * as Types from "./mutation-types";

export default {
  // state, filters
  [Types.SEND_FILTERS]() {},
  [Types.RESET_FILTER_PAYLOAD](state) {
    state.filterPayload = {};
    state.filterModel = {};
  },
};
