import { HttpClient } from "@/shared/services";

class AuthService {
  endpoint = "auth";

  /**
   * @param {{username: string; password: string}} payload
   * @returns {*}
   */
  async login(payload) {
    try {
      const res = await HttpClient.post(`${this.endpoint}/login`, payload);
      return res?.data.data;
    } catch (err) {
      return err;
    }
  }

  async refreshUser() {
    try {
      const res = await HttpClient.get(`${this.endpoint}/me`);
      return res?.data;
    } catch (err) {
      return err;
    }
  }
}

export default new AuthService();
