import { defaultState } from "@/shared/utilities/constants";

export default () => ({
  absences: { ...defaultState },
  dates: [],
  reasons: {
    count: 0,
    data: [],
  },
});
