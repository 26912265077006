import { HttpClient } from "@/shared/services";
import { createQueryParams } from "@/shared/utilities/query-params.util";

class StudentResultService {
  endpoint = "session/supervisors-result";

  async result(slectedDate, slectedOrganization, queryParams) {
    try {
      const res = await HttpClient.post(`${this.endpoint}/show${createQueryParams(queryParams)}&organization=${slectedOrganization}` , slectedDate);
      return res.data;
    } catch (err) {
      return err;
    }
  }

  async exportResult(slectedDate, slectedOrganization) {
    try {
      const res = await HttpClient.post(`${this.endpoint}/export?organization=${slectedOrganization}` , slectedDate, { responseType: 'blob' });
      return res.data;
    } catch (err) {
      return err;
    }
  }

}

export default new StudentResultService();
